import React from 'react';
import Grid from '@material-ui/core/Grid';
import s from './sectorlist.scss';
import { Typography } from '@material-ui/core';
interface SectorModel {
    sectoricon: string;
    sectorname: string;
    sectorlink: string;
  }


  export const Sector = ({sectoricon, sectorname, sectorlink}: SectorModel) => {
    return ( // <------ must return here
        <Grid item md={2} xs={6} sm={3} className={s.dataset__item}>
           {/* <img className={sl.logo}  src={logo} alt="" /> */}
           <img className={s.logo} src={"../../../png/" + sectoricon} />
        <Typography component="p" className={s.dataset__label}>{sectorname}</Typography>
      </Grid>
      )
  }
  

  export  class SectorList extends React.Component {
    render() {
      return (
        <Grid container className={s.dataset__list}>
          {this.props.data.map((item, index) => <Sector key={index} {...item} />)}
        </Grid>
      );
    }
  }
// export const SectorList = () =>{
//     console.log("thanks", props.data);
//     (
//         <div>
//             {props.data.map(item => <ListItem key={item.id} {...item}/>)}
//       </div>
//     );
// } 
