import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Vision } from './Vision/Vision';
import { Mission } from './Mission/Mission';
import { Video } from './Video/Video';
import a from './About.scss';
export const AboutSection = () =>{


    return(


        <Grid container>
            <Grid item md={6} className={a.AboutNDAP__margint}>
                <Typography className={a.AboutNDAP__headline} gutterBottom>About NDAP</Typography>
           <Vision />
            <Mission />
            </Grid>
            <Grid item md={6}>
           
           <Video />
            </Grid>
        </Grid>
       
    )
}