import React from 'react'
import { Typography, Grid, NoSsr } from '@material-ui/core'
import ReactPlayer from 'react-player'
import v from './Video.scss'

export const Video = () => {

    return(
        <Grid container justify="flex-end">
            <Grid item  className={v.Video__margint} >
                <NoSsr defer={true}>
            <ReactPlayer  url="https://www.youtube.com/watch?v=yEZRdUsDmOw" width="450px" height="240px"/>
            </NoSsr>
            <Grid item className={v.Video__VSubtitle}>
            <Typography gutterBottom>Quick walkthrough of NDAP portal</Typography>
            </Grid>
       
            </Grid>
       
        </Grid>
    )
}