import React, { ReactNode } from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Typography from '@material-ui/core/Typography';
import h from '../indicators-list/indicatorslist.scss';
import Iconimg from 'components/iconimg';
// import colors from 'styles/colors.scss';

interface IndicatorsModel {
    indicon: string;
    indtitle: string;
    indcontent: string;
    indlink: string;
  }

export const Indicator = ({icon, indtitle, indcontent,indlink}: IndicatorsModel) => {

  // console.log('color::', colors.colorFont)
    return ( // <------ must return hereindcontent
        <>
        <Grid item md={4} sm={4} xs={12}>
        <Card className={h.homeindicators__card}>
          <CardContent className={h.homeindicators__content}>
            <ArrowForwardIcon className={h.homeindicators__cardnext}></ArrowForwardIcon>
            <Typography variant="h6" component="div" className={h.homeindicators__cardtitle}>
            <Iconimg icon={icon}></Iconimg>
              {indtitle}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {indcontent}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      </>
      )
  }

export  class IndicatorsList extends React.Component {
    render() {
      return (
        <>
        <div className={h.homeindicators}>
       <Grid container spacing={3}>
       {this.props.list.map((item, index) => <Indicator key={index} {...item} />)}
       </Grid>
       </div>
        </>
      );
    }
  }

