import React from 'react'
import { Typography, Grid } from '@material-ui/core'
import m from './Mission.scss'

export const Mission = () => {

    return(
        <Grid container>
       <Typography className={m.Mission__MColor} gutterBottom>MISSION</Typography>
            <Typography className={m.Mission__LeftAlign}>
            Standardize data across multiple Government sources, provide flexible analytics and make it easily accessible in formats conducive for research, innovation, policy making and public consumption.
            </Typography>
        </Grid>
    )
}