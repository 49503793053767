import React, { ReactNode } from 'react';
import Typography from '@material-ui/core/Typography';
import s from '../search-list/searchlist.scss';
import Link from '@material-ui/core/Link';

interface SearchModel {
    searchtext: string;
  }

export const SearchTerm = ({searchtext}: SearchModel) => {
    return ( // <------ must return hereindcontent
        <>
        <Link href="#">{searchtext}</Link>
      </>
      )
  }

export  class SearchList extends React.Component {
    render() {
      return (
        <>
           <Typography component="span" className={s.searchlist}>
           {this.props.searchterms.map((item, index) => <SearchTerm key={index} {...item} />)}
           </Typography>
        </>
      );
    }
  }

