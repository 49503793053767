import React from 'react';
import { Intro } from 'components/intro/Intro';
import { Highlight } from 'components/intro/Highlight';
import { BlockText } from 'components/block-text/BlockText';
import Typography from '@material-ui/core/Typography';
import SEO from 'components/seo';
import s from '../pages/index.scss';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Container from '@material-ui/core/Container';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import { AboutSection } from '../modules/home/about-section/About';
import { SectorList } from 'components/sector-list/SectorList';
import {IndicatorsList} from 'components/indicators-list/indicatorslist';
import {SearchList} from 'components/search-list/SearchList';
const searchlist = [
  {searchtext:'Education'},
  {searchtext:'Finance'},
  {searchtext:'Health'},
  {searchtext:'Agriculture'},
  {searchtext:'Chemical & Pharma'},
  {searchtext:'Women & Child Development'}
    ];
    const sectorlist = [
      {
        sectoricon: 'ic-agriculture-active.png',
        sectorname: 'Agriculture',
        sectorlink: 'link1',
      },
      {
        sectoricon: 'ic-business-active.png',
        sectorname: 'Business',
        sectorlink: 'link2',
      },
      {
        sectoricon: 'ic-commerce-active.png',
        sectorname: 'Commerce',
        sectorlink: 'link3',
      },
      {
        sectoricon: 'ic-communications.png',
        sectorname: 'Communications',
        sectorlink: 'link4',
      },
      {
        sectoricon: 'ic-defence-active.png',
        sectorname: 'Defence',
        sectorlink: 'link5',
      },
      {
        sectoricon: 'ic-economy-active.png',
        sectorname: 'Economy',
        sectorlink: 'link6',
      },
      {
        sectoricon: 'ic-education-active.png',
        sectorname: 'Education',
        sectorlink: 'link7',
      },
      {
        sectoricon: 'ic-environment-active.png',
        sectorname: 'Environment & Forest',
        sectorlink: 'link8',
      },
      {
        sectoricon: 'ic-finance-active.png',
        sectorname: 'Finance & Taxes',
        sectorlink: 'link9',
      },
      {
        sectoricon: 'ic-food-active.png',
        sectorname: 'Food',
        sectorlink: 'link10',
      },
      {
        sectoricon: 'ic-health-active.png',
        sectorname: 'Health & Family Welfare',
        sectorlink: 'link11',
      },
      {
        sectoricon: 'ic-housing-active.png',
        sectorname: 'Housing',
        sectorlink: 'link12',
      },
    ];
  
    const indicatorslist = [
      {
        icon: 'ic-indicator-active.png',
        indtitle:'Indicator',
        indcontent:'Browse through an exhaustive list of indicators identified across datasets',
        indlink:'link1'
      },
      {
        icon: 'ic-sources-active.png',
        indtitle:'Sources',
        indcontent:'Browse through datasets from various sources at central and state government level.',
        indlink:'link2'
      },
      {
        icon: 'ic-states-active.png',
        indtitle:'States/UTs',
        indcontent:'Browse through the list of Indian states and Union Territories and view key indicators at a glance',
        indlink:'link3'
      }
    ];


export default () => {

  return (
    <>
      <SEO title="Home" />
     
      <Grid container>
        <div className={s.homewrap}>
          <Typography variant="h5" component="h5" align="center" className={s.homewrap__title}>
            National Data Analytics Platform <br />
            (NDAP)
          </Typography>

          <form className={s.homewrap__searchbar}>
            <InputBase
              placeholder="Example: Education in Maharashtra year of 2018 to 2019"
              inputProps={{ 'aria-label': 'Education in Maharashtra year of 2018 to 2019' }}
              className={s.homewrap__searchinput}
              
            />

            <Button type="submit" className={s.homewrap___searchbtn}>
              <SearchIcon />
            </Button>
            
          
          </form>

          <div className={s.popularsearch}>
            
            <Typography component="strong">Popular Searches :</Typography>
            <SearchList searchterms={searchlist}></SearchList>
          </div>
          <Typography variant="caption" className={s.popularsearch__info}>
            Need help with using NDAP Portal? <Link href="#">Tutorials</Link>
          </Typography>
        </div>
      </Grid>
      <div className={s.dataset}>
        <SectorList data={sectorlist} />
        <div className={s.dataset__expand}>
          <ExpandMoreIcon></ExpandMoreIcon>
        </div>
      </div>
      <Grid container>
      <IndicatorsList list={indicatorslist}/>
      </Grid>
      <Grid container>
      <AboutSection />
      </Grid>



    </>
  );
}

