import React from 'react'
import { Typography, Grid } from '@material-ui/core'
import s from './Vision.scss';

export const Vision = () => {

    return(
        <Grid container>
            
        <Typography className={s.Vision__VColor} gutterBottom >VISION</Typography>
        <Typography gutterBottom className={s.Vision__LeftAlign}>
        Democratize access to public Government data through a world-class user experience.
        </Typography>
        </Grid>
    )
}